<template>
  <div>
    <div class="d-flex justify-space-between page-title">
      <div>
        <h1>Verenigingen</h1>
        <h5>{{ formattedDate() }}</h5>
      </div>
    </div> 

    <div class="text-center">      
      <p class="small-centered-p">Aantal actieve verenigingsleden: {{ totalRequest }}</p>
      <p class="small-centered-p">Aantal opgezegd deze maand: 567</p>
    </div>

    <div class="dashboard-card">
      <v-simple-table>
        <tbody>
          <tr v-for="association in associations" :key="association.id">
            <td>{{ association.association_name }}</td>
            <td>{{ association.total }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-btn outlined class="btn-terug" text min-width="100%" to="/dashboard">Terug</v-btn>

  </div>
</template>

<script>
import BackendService from "../services/BackendService"

export default {
  data () {
    return {
      menu: false,
      modal: false,
      items: ['Oktober', 'November', 'December'],
      associations: [],
      year: "",
      month: "",
      day: "12"
    }
  },
  computed: {
    totalRequest() {
      return this.associations.reduce((acc, item) => acc + item.total, 0);
    }
  },
  created() {
    const year = new Date()
    const month = new Date()
    const day = new Date()

    BackendService.getAssociations(year.getFullYear(), month.getMonth() + 1, day.getDate())
      .then((response) => {
        let items = response.data.splice(41, 9)
        console.log(items)
        this.associations = response.data
      })
      .catch(err => {
        console.log(err) 
      })
  }
}
</script>

<style scoped>
.small-centered-p {
  margin: 20px;
  font-size: 0.8em;
  text-align: center;
}
</style>