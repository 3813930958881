<template>
  <div>
    <div class="d-flex justify-space-between page-title">
      <div>
        <h1>Ledenverloop</h1>
        <h5>{{ formattedDate() }}</h5>
      </div>
    </div>  

    <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="dates"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            v-model="dates"
            readonly
            v-bind="attrs"
            v-on="on"
            class="choose-month-btn"
          >
            Kies een periode
          </v-btn>
        </template>
        <v-date-picker
          v-model="dates"
          range
          scrollable
          color="#74bcb7"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            Annuleren
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(dates) | fetchShop()"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

    <div class="dashboard-card">
      <v-card-subtitle>Ledenverloop</v-card-subtitle>
      <div class="small">
        <chart :chart-data="datacollection"></chart>
      </div>
    </div>

    <v-btn outlined class="btn-terug" text min-width="100%" to="/relaties/ledenopbouw">Terug</v-btn>

  </div>
</template>

<script>
import BackendService from "../services/BackendService"
import Chart from '../components/Chart.js'

export default {
  components: {
    Chart
  },
  data () {
    return {
      datacollection: {},
      turnover: {},
      dates: [],
      modal: false
    }
  },
  created() {
    const lastYear = new Date().getFullYear() - 1
    const thisYear = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const day = new Date().getDate()
    const startDate = [lastYear, month, day].join('-')
    const today = [thisYear, month, day].join('-')

    BackendService.getTurnover(startDate, today)
    .then((response) => {
      this.turnover = response.data

      this.datacollection = {
        labels: [today], 
        datasets: 
        [
          {
            label: 'Nieuw',
            backgroundColor: '#ffd948',
            data: [this.turnover.registered]
          },
          {
            label: 'Opgezegd',
            backgroundColor: '#74BCB7',
            data: [this.turnover.cancelled]
          }
        ]
      }
    })
    .catch(err => {
      console.log(err)
    })
  },
  methods: {
    fetchShop() {
      BackendService.getTurnover(this.dates[0], this.dates[1])
      .then((response) => {
        this.turnover = response.data

        this.datacollection = {
          labels: [this.dates],
          datasets: 
          [
            {
              label: 'Nieuw',
              backgroundColor: '#ffd948',
              data: [this.turnover.registered]
            },
            {
              label: 'Opgezegd',
              backgroundColor: '#74BCB7',
              data: [this.turnover.cancelled]
            }
          ]
        }

      })
      .catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.btn-verloop {
  margin-top: 10px;
  background: #436d6a !important;
}

.v-btn {
  margin-top: 25px;
}

.v-icon {
  color: #fff !important;
}

.btn-calender {
  background: #74BCB7 !important;
}
</style>