<template>
  <div class="full-width-login">
    <h1>Welkom</h1>
    <h3>Login om verder te gaan</h3>
    <br /><br />
    <div class="login-form">
      <h2>Inloggen</h2>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="submitForm"
        lazy-validation
      >
        <v-text-field
          class="inlog-field"
          label="E-mailadres"
          type="text"
          placeholder="Gebruikersnaam"
          v-model="username"
          :rules="usernameRules"
          required
          validate-on-blur
        ></v-text-field>
        <v-text-field
          class="inlog-field"
          label="Wachtwoord"
          type="password"
          placeholder="Wachtwoord"
          v-model="password"
          required
          :rules="[v => !!v || 'Wachtwoord is verplicht']"
          validate-on-blur
        ></v-text-field>
        <a :href="resetURL"  class="lost-password" target="_blank">Wachtwoord vergeten?</a>
        <br />
        <br />
        <v-btn class="no-border-shadow no-margin login-btn" type="submit">Inloggen</v-btn>
        <br><br>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";


export default {
  created() {
    if (this.loggedIn) {
      this.logout();
    }
  },
  data() {
    return {
      valid: true,
      username: "",
      password: "",
      usernameRules: [
        v => !!v || "E-mailadres is verplicht",
        v => /.+@.+/.test(v) || "Geen geldig e-mailadres"
      ]
    };
  },
  computed: {
    ...mapState("user", ["loggedIn"]),
      resetURL() {
          return process.env.VUE_APP_BACKEND_URL+'/user/forgot';
      }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    submitForm() {
      if (this.validate()) {
        const { username, password } = this;
        this.login({ username, password })
          .then(() => {
            this.$store.dispatch("notification/add", {
              message: "Ingelogd als " + username
            });
            this.$router.push("/dashboard")
          })
          .catch(error => {
            console.log(error)
          });
      } else {
        let notification = {
          type: "error",
          message: "Kan niet inloggen met deze gegevens"
        };
        this.$store.dispatch("notification/add", notification);
      }
    },
    formattedDate () {
      const date = new Date();

      return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'full' }).format(date);
    },
    ...mapActions("user", ["login", "logout"])
  }
};
</script>

<style scoped>
h1 {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
}

h2 {
  color: #797979;
}

h3 {
  color: #378080;
  font-weight: 600;
}

.login-btn {
  background: #53AED9 !important;
  color: #fff;
  text-transform: none;
  font-weight: 600;
  padding-bottom: 15px;
  width: 100%;
  border-radius: 17px;
}

a.lost-password {
  color: #AEAEAE !important;
  margin-bottom: 20px;
}

.container {
  min-width: 100vw;
  padding: 0 !important;
}

.container.container--fluid {
  min-width: 100vw !important;
  padding: 0 !important;
}

.full-width-login {
  min-height: 96vh;
  padding: 100px 35px;
  background: url("../../public/img/loginsplash.png");
  background-size: cover;
  background-position: center top;
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
}

.login-form {
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.15);
  padding: 20px;
}

.inlog-field:after {
  width: 0% !important;
}

.v-input {
  padding: 20px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border-radius: 17px !important;
  height: 50px !important;
  background: #F2F2F2 !important;
}

</style>