<template>
  <div>
    <h1>Dashboard</h1>
    <h5>{{ formattedDate() }}</h5>
    <div v-if="!loggedIn" class="dashboard-text">
      <strong>Inloggen</strong>
      <p>Om de app te kunnen gebruiken moet je inloggen. Hiervoor kan in het NFN beheer platform een gebruikersaccount voor gemaakt worden. Bij een gebruikersaccount moet je specifiek aangeven dat je gebruik mag maken van deze app.</p>

      <strong>Dashboard</strong>
      <p>Je start op een overal dashboard pagina waar je direct een overzicht krijgt over het aantal leden, de omzet van de shop en advertenties. Per onderdeel kun je doorklikken naar de details behorende bij dat onderdeel.</p>

      <strong>Menu</strong>
      <p>Je kunt ook via het menu naar de verschillende onderdelen navigeren. Als je een onderdeel aanklikt in het menu dan wordt die pagina gestart en bijbehorende gegevens worden opgehaald. Je kunt via het menu ook altijd weer naar de startpagina en vanuit daar weer doorgaan.</p>

      <strong>Datum selectie maken</strong>
      <p>Een aantal pagina's toont gegevens van de actuele maand. Hier kun je altijd de periode wijzigen via 'Kies een periode'. Door de begindatum en einddatum aan te klikken wordt een periode geselecteerd. Klik op OK en je krijgt de gegevens getoond met betrekking tot de geselecteerde periode.</p>

      <strong>Herladen</strong>
      <p>Wil je de app weer even op beginstand zetten, zoals je app start, klik dan op 'Herladen'. De app wordt dan opnieuw geladen.</p>

      <v-btn text small outlined to="/login">Hier kunt u inloggen.</v-btn>
    </div>
    
    <div v-if="loggedIn">
      <p>Welkom, {{ profile.name }}</p>
      <div class="dashboard-card">
        <v-card-title>Leden NFN: {{ members.members }}</v-card-title>
        <v-card-subtitle>Aantal relaties: {{ members.total }}</v-card-subtitle>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn small text class="btn-details" to="/relaties">
              Details
            </v-btn>
          </v-col>
        </v-card-actions>
      </div>
      
      <div class="dashboard-card">
        <v-card-title>Shop: €{{ Number(shop.amount).toLocaleString() }}</v-card-title>
        <v-card-subtitle>Aantal bestellingen: {{ shop.new_orders }}</v-card-subtitle>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn small text class="btn-details" to="/webshop">
              Details
            </v-btn>
          </v-col>
        </v-card-actions>
      </div>

      <div class="dashboard-card">
        <v-card-title>Advertenties: €{{ Number(displayorder.amount).toLocaleString() }}</v-card-title>
        <v-card-subtitle>Aantal orders: {{ displayorder.new_orders }}</v-card-subtitle>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn small text class="btn-details" to="/advertenties">
              Details
            </v-btn>
          </v-col>
        </v-card-actions>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BackendService from "../services/BackendService"
import axios from 'axios'

export default {
  data() {
    return {
      year: "",
      month: "",
      day: 12,
      members: "",
      shop: "",
      brand: "",
      displayorder: "",
      group: ""
    }
  },
  created() {
    const lastYear = new Date().getFullYear() - 1
    const thisYear = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const day = new Date().getDate()
    const startDate = [lastYear, month, day].join('-')
    const today = [thisYear, month, day].join('-')

    axios.all([
      BackendService.getMembers(thisYear, month, day),
      BackendService.getShop(startDate, today),
      BackendService.getGroup(thisYear, month, day),
      BackendService.getAssociations(thisYear, month, day),
      BackendService.getBrand(thisYear, month, day),
      BackendService.getDisplayOrder(startDate, today)
    ])
    .then(axios.spread((members, shop, group, associations, brand, displayorder) => {
      this.members = members.data,
      this.shop = shop.data,
      this.group = group.data,
      this.associations = associations.data,
      this.brand = brand.data
      this.displayorder = displayorder.data
    }))
    .catch(err => {
      console.log(err)
    });
  },
  computed: {
    ...mapState("user", ["loggedIn", "profile"])
  },
  methods: {
    formattedDate () {
      const date = new Date();

      return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'full' }).format(date);
    }
  }
};
</script>

<style scoped>
.container {
  padding: 35px 0 !important;
}

.dashboard-text {
  margin-top: 20px;
  font-size: 0.8em;
}

.col {
  padding: 0px !important;
}
</style>