<template>
  <div>
    <div class="d-flex justify-space-between page-title">
      <div>
        <h1>Relaties</h1>
        <h5>{{ formattedDate() }}</h5>
      </div>
    </div>

    <div class="dashboard-card">
      <v-simple-table>
        <tbody>
          <tr>
            <td>Aantal actieve leden</td>
            <td>{{ members.members }}</td>
          </tr>
          <tr>
            <td>Aantal leden</td>
            <td>{{ members.total }}</td>
          </tr>
          <tr>
            <td>Aantal leden vereniging</td>
            <td>{{ totalRequest }}</td>
          </tr>
          <tr>
            <td>Aantal klanten</td>
            <td>{{ shop.customers }} </td>
          </tr>
          <tr>
            <td>Aantal organisaties</td>
            <td>{{ group.length }}</td>
          </tr>
          <tr>
            <td>Aantal verenigingen</td>
            <td>{{ associations.length }}</td>
          </tr>
          <tr>
            <td>Relaties Blootgewoon</td>
            <td>{{ brand ? brand.find(findBlootgewoon).total : "Loading..." }}</td>
          </tr>
          <tr>
            <td>Relaties NatuurlijkNFN</td>
            <td>{{ brand ? brand.find(findNatuurlijkNFN).total : "Loading..." }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-btn text class="btn-details" min-width="100%" to="/relaties/ledenopbouw">Leden opbouw</v-btn>
    <v-btn class="btn-verloop" text min-width="100%" to="/relaties/ledenverloop">Leden verloop</v-btn>

    <v-btn outlined class="btn-terug" text min-width="100%" to="/dashboard">Terug</v-btn>
    
  </div>
</template>

<script>
import BackendService from "../services/BackendService"
import axios from 'axios'

export default {
  components: {},
  data () {
    return {
      associations: [],
      year: "",
      month: "",
      day: 12,
      members: "",
      shop: "",
      brand: "",
      group: ""
    }
  },
  created() {
    const lastYear = new Date().getFullYear() - 1
    const thisYear = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const day = new Date().getDate() 

    const startDate = [lastYear, month, day].join('-')
    const today = [thisYear, month, day].join('-')

    axios.all([
      BackendService.getMembers(thisYear, month, day),
      BackendService.getShop(startDate, today),
      BackendService.getGroup(thisYear, month, day),
      BackendService.getAssociations(thisYear, month, day),
      BackendService.getBrand(thisYear, month, day)
    ])
    .then(axios.spread((members, shop, group, associations, brand) => {
      this.members = members.data,
      this.shop = shop.data,
      this.group = group.data,
      this.associations = associations.data,
      this.brand = brand.data
    }))
    .catch(err => {
      console.log(err)
    });
  },
  computed: {
    totalRequest() {
      return this.associations.reduce((acc, item) => acc + item.total, 0);
    }
  },
  methods: {
    findBlootgewoon(brand) {
      return brand.brand_name === 'BlootGewoon!'
    },
    findNatuurlijkNFN(brand) {
      return brand.brand_name === 'Natuurlijk! NFN'
    }
  },
}
</script>

<style scoped>
.small {
  max-width: 70vw !important;
  margin:  0 auto;
  padding: 0 0 20px 0;
}

.v-icon {
  color: #fff !important;
}

.btn-calender {
  background: #74BCB7 !important;
}
</style>