<template>
  <div class="justify-space-between page-title">
    <v-form
      v-model="valid"
      lazy-validation
      class="full"
    >
      <v-window v-model="step" touchless class="windowcontainer">
        <v-window-item :value="1">
          <div>
            <h1>Briefverwerking</h1>
            <h3>Voor het registratienummer in:</h3> 
            <input type="number" class="registratienummer" v-model="regNumber" />
            <v-btn outlined @click="fetchMember" class="btn-registratienummer" text min-width="100%" to="">Haal gegevens op</v-btn>
          </div>
          <div v-if="userData">
            <div v-if="showUserData" class="user-data">
              <h3>Gegevens</h3>
              <div class="data white">
                <p>{{ userData.name }}</p> 
                <p>{{ userData.date_of_birth | convertDate }}</p> 
                <p>{{ userData.address.street }} {{ userData.address.number }}{{ userData.address.number_appendix }}</p> 
                <p>{{ userData.address.city }}</p> 
              </div> 

              <h3>E-mail:</h3> 
              <input type="email" v-model="email" class="registratienummer" />

              <div class="data invoice">
                <label class="checkbox-container">
                <input type="checkbox" @click="invoicePerPost">
                  <span class="checkmark"></span>
                </label>
                <p>Ik wil de factuur per post ontvangen.</p>
              </div>

              <v-btn @click="memberConfirm" outlined class="btn-akkoord" text min-width="100%" to="">Gegevens zijn akkoord</v-btn>
              <v-btn outlined class="btn-niet-akkoord" text min-width="100%" @click="clearData">Gegevens zijn niet akkoord</v-btn>
            </div>
          </div>
          <div v-if="error" class="data white iserror">
            <p>Geen gegevens gevonden.</p>
          </div>
        </v-window-item>

        <v-window-item :value="2">
          <h1>Briefverwerking</h1>
          <h3>Maak een foto van de brief.</h3>

          <label for="letter" class="custom-file-upload">
            Maak foto
          </label>
          <input type="file" id="letter" accept="image/*" :name="uploadFieldName" capture="environment" @change="Images_onFileChanged">
        </v-window-item>

        <v-window-item :value="3">
          <div class="center">
            <v-icon size="150" color="#68BBB4">check</v-icon>
            <h1>registratie geslaagd!</h1>
            <v-btn outlined @click="newAddition" class="btn-registratienummer" text min-width="100%" to="">Voer nog een briefverwerking in</v-btn>
            <v-btn outlined @click="$router.push({ name: 'home' })" class="btn-niet-akkoord" text min-width="100%" to="">Terug naar dashboard</v-btn>
          </div>
          
        </v-window-item>
      </v-window>
    </v-form>
  </div> 
</template>

<script>
import { mapState } from "vuex"
import moment from 'moment'
import BackendService from '../services/BackendService'

export default {
  data () {
    return {
      valid: true,
      step: null,
      uploadedFiles: [],
      uploadFieldName: 'photos',
      datacollection: {},
      modal: false,
      regNumber: null,
      showUserData: false,
      photo: "",
      email: "",
      userData: {},
      selectedFile: null,
      error: false,
      invoicePost: 0,
    }
  },
  created() {
    if(!this.loggedIn) {
      this.$router.push({ name: 'login' })
    }
  },
  methods: {
    fetchMember() {
      let number = this.regNumber

      BackendService.getMember(number)
        .then((response) => {
          if(response.data.error) {
            this.error = true
            setTimeout(() => {
              this.error = false;
            }, 3000);
            return
          }
          this.userData = response.data
          this.email = this.userData.email

          if(response.data) this.showUserData = true
        })
    },
    fetchBriefverwerkingData() {
      if(this.regNumber) this.showUserData = true;
    },
    Images_onFileChanged (event) {
      this.selectedFile = event.target.files[0];

      this.filesChange()
    },
    clearData() {
      this.regNumber = null
      this.userData = null
      this.showUserData = false
    },
    newAddition() {
      this.clearData() 
      this.step = 1
    },
    memberConfirm() {
      let number = this.regNumber
      let email = this.email 

      BackendService.confirmMember(email, number)
      .then(() => {
        this.step++
      })
    },
    filesChange() {
      let number = this.regNumber
      let email = this.email
      let file = this.selectedFile

      BackendService.uploadPhoto(number, email, file)
      .then(() => {
        this.createSubscriptionAndInvoice()
        this.step++
      })
    },
    invoicePerPost() {
      this.invoicePost = !this.invoicePost
      console.log("checked or not?", this.invoicePost)
    },
    createSubscriptionAndInvoice() {
      let number = this.regNumber
      let invoice = this.invoicePost

      BackendService.createSubscriptionAndInvoice(number, invoice)
      .then((response) => {
        console.log("what is subscription response?", response.data)
      })
    }
  },
  filters: {
     convertDate (value) { 
      if (value) {
        return moment((value) * 1000).format('DD-MM-YYYY')
      }
    }
  },
  computed: {
    ...mapState("user", ["loggedIn", "profile"])
  },
}
</script>

<style scoped>
.page-title {
  width: 100%;
  justify-content: space-between;
}

.page-title h1 {
  margin-bottom: 35px;
  color: #68BBB4;
}

.btn-registratienummer {
  background: #68BBB4;
  color: #fff;
  font-weight: bold;
  text-transform: initial;
  margin-top: 15px;
  border: none;
}

.btn-akkoord {
  background: #68BBB4;
  color: #fff;
  font-weight: bold;
  text-transform: initial;
  margin-top: 15px;
  border: none;
}

.btn-niet-akkoord {
  background: #C4C4C4;
  color: #fff;
  font-weight: bold;
  text-transform: initial;
  margin-top: 15px;
  border: none;
}

.registratienummer {
  width: 100%;
  padding: 12px;
  background: #fff;
  color: #68BBB4;
  border: 1px solid #68BBB4;
  font-weight: bold;
  font-size: 18px;
}

.center {
  text-align: center;
  padding-top: 100px;
}

.center h1 {
  color: black;
  margin-bottom: 10px;
}

h3 {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.data.white {
  padding: 20px;
  background: white;
}

.data.white p {
  font-size: 14px;
  color: #909090;
  margin-bottom: 8px;
}

.data.white.iserror {
  margin-top: 20px;
}

.data.invoice {
  margin-top: 20px;
  padding: 20px 0;
  display: flex;
  align-items: center;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #68BBB4;
}

.container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.container input[type="checkbox"]:checked ~ .checkmark {
  background-color: #68BBB4;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}





.invoice p {
  margin: 0;
}

/* .invoice:before {
  content: "□";
  margin-right: 10px;
} */

input[type="file"] {
  display: none;
}

.custom-file-upload {
  background: #68BBB4;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
  padding: 18px 12px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
</style>