import Vue from 'vue'
import Vuex from 'vuex'
import * as drawer from "@/store/modules/drawer";
import * as notification from "@/store/modules/notification";
import * as user from "@/store/modules/user";
import * as statistics from "@/store/modules/statistics";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    drawer,
    notification,
    user,
    statistics
  }
})
