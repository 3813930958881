import BackendService from "@/services/BackendService";
import axios from 'axios'

export const namespaced = true;

const profile = JSON.parse(localStorage.getItem("profile"));

export const state = profile
  ? { loggedIn: true, profile }
  : { loggedIn: false, profile: null };

export const mutations = {
  LOGIN(state) {
    state.loggedIn = true;
  },
  LOGIN_ERROR(state) {
    state.loggedIn = false;
    state.profile = null;
  },
  LOGOUT(state) {
    state.loggedIn = false;
    state.profile = null;
  },
  SET_PROFILE(state, profile) {
    state.profile = profile;
  }
};

export const actions = {
  login({ dispatch, commit }, { username, password }) {
    return BackendService.login(username, password)
      .then(() => {
        dispatch("fetchProfile")
        // return user;
      })
      .catch(error => {
        let notification = {
          type: "error",
          message: "Kan niet inloggen met deze gegevens"
        };
        dispatch("notification/add", notification, { root: true });
        commit("LOGIN_ERROR", error);
        throw error;
      });
  },
  logout({ commit }) {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");
    delete axios.defaults.headers.common["Authorization"];
    commit("LOGOUT")
  },
  fetchProfile({ commit }) {
    return BackendService.getUserProfile()
      .then(({ data }) => {
        window.localStorage.setItem("user", JSON.stringify(data));
        commit("SET_PROFILE", data);
        commit("LOGIN");
      })
      .catch(error => {
        console.log("There was an error", error);
      });
  }
};

export const getters = {
  isGuest: state => {
    return !state.loggedIn;
  },
  getProfile: state => {
    return state.profile;
  }
};
