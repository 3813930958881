import BackendService from "@/services/BackendService";

export const namespaced = true;

export const state = {
  associations: []
};

export const mutations = {
  SET_ASSOCIATIONS(state, { associations }) {
    state.associations = associations
  },
}

export const actions = {
  fetchAssociations({ state, commit }) {
    if (state.associations.length === 0) {
      return BackendService.getAssociations()
        .then(({ data }) => {
          commit("SET_ASSOCIATIONS", data);
        })
        .catch(error => {
          console.log("There was an error", error);
        });
    }
  },
}

export const getters = {
  getAssociations: state => () => {
    return state.associations
  },
}