<template>
  <v-navigation-drawer v-model="drawer" fixed app :width="drawerWidth">
      <v-app-bar app dark fixed>
          <v-app-bar-nav-icon @click.stop="toggleDrawer" class="hidden-sm-and-down"></v-app-bar-nav-icon>
          <v-toolbar-title>
            <img
            @click.stop="$router.push({ name: 'home' })"
            src="/img/logo_nfn.svg"
            class="logo-header-white"
            height="40"
            />
          </v-toolbar-title>
      </v-app-bar>

    <v-list nav class="nav-margin">
      <v-list-item v-if="loggedIn" to="/dashboard">
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="loggedIn" to="/relaties">
        <v-list-item-content>
          <v-list-item-title>Relaties</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="loggedIn" to="/verenigingen">
        <v-list-item-content>
          <v-list-item-title>Verenigingen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="loggedIn" to="/webshop">
        <v-list-item-content>
          <v-list-item-title>Webshop</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="loggedIn" to="/advertenties">
        <v-list-item-content>
          <v-list-item-title>Advertenties</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="loggedIn" to="/briefverwerking">
        <v-list-item-content>
          <v-list-item-title>Briefverwerking</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/hoewerkthet">
        <v-list-item-content>
          <v-list-item-title>Hoe werkt het?</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="!loggedIn" to="/login">
        <v-list-item-content>
          <v-list-item-title>Inloggen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="loggedIn" to="/logout">
        <v-list-item-content>
          <v-list-item-title>Uitloggen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="refresh">
        <v-list-item-content>
          <v-list-item-title>Herladen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-btn
        fixed
        fab
        bottom
        right
        color="dark"
        @click.stop="setDrawer(false)"
        class="hidden-sm-and-up no-border-shadow"
    >
      <v-icon>close</v-icon>
    </v-btn>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SideNav",
  computed: {
    drawer: {
      get: function() {
        return this.$store.state.drawer.showDrawer;
      },
      set: function(v) {
        this.setDrawer(v);
      }
    },
    drawerWidth: function() {
      let w = window.innerWidth;
      if (w < 600) { // sm breakpoint
        return w;
      } else {
        return 320;
      }
    },
    ...mapState("user", ["loggedIn", "user"])
  },
  methods: {
    refresh() {
      caches.keys().then(function(names) {
        for (let name of names) {
          caches.delete(name);
        }
        window.location.reload(true);
      });
    },
    ...mapActions("drawer", ["setDrawer"])
  }
};
</script>

<style scoped>
.v-navigation-drawer {
  background: black;
}
.v-navigation-drawer__content {
  background: #74BCB7 !important;
}

.v-list-item__title {
  color: #fff !important;
}

</style>
