<template>
  <div>
    <div class="d-flex justify-space-between page-title">
      <div><h1>Ledenopbouw</h1>
        <h5>{{ formattedDate() }}</h5>
      </div>
    </div>  
    <div class="dashboard-card">
      <v-simple-table dense>
        <tbody>
          <tr>
            <td>Aantal actieve leden</td>
            <td>{{ members.members }}</td>
          </tr>
          <tr>
            <td>Aantal leden</td>
            <td>{{ members.total }}</td>
          </tr>
          <tr>
            <td>Aantal man</td>
            <td>{{ members.gender_m }}</td>
          </tr>
          <tr>
            <td>Aantal vrouw</td>
            <td>{{ members.gender_f }}</td>           
          </tr>
          <tr>
            <td>Aantal Geen geslacht</td>
            <td>{{ members.gender_x }}</td>  
          </tr>
          <tr>
            <td>Leden &lt; 18</td>
            <td>{{ members.age_0 }}</td>  
          </tr>
          <tr>
            <td>Leden 18-25</td>
            <td>{{ members.age_18 }}</td>  
          </tr>
          <tr>
            <td>Leden 25-26</td>
            <td>{{ members.age_25 }}</td>  
          </tr>
          <tr>
            <td>Leden 26-35</td>
            <td>{{ members.age_26 }}</td>  
          </tr>
          <tr>
            <td>Leden 35-49</td>
            <td>{{ members.age_35 }}</td>  
          </tr>
          <tr>
            <td>Leden 50-67</td>
            <td>{{ members.age_50 }}</td>  
          </tr>
          <tr>
            <td>Leden 67+</td>
            <td>{{ members.age_67 }}</td>  
          </tr>
          <tr>
            <td>Portaal NFN</td>
            <td>{{ members.association_members }}</td>  
          </tr>
          <tr>
            <td>Portaal Vereniging</td>
            <td>{{ members.association_members_activated }}</td>  
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-btn class="btn-verloop" text min-width="100%" to="/relaties/ledenverloop">Leden verloop</v-btn>

    <v-btn outlined class="btn-terug" text min-width="100%" to="/relaties">Terug</v-btn>

  </div>
</template>

<script>
import BackendService from "../services/BackendService"

  export default {
    components: {
    },
    data () {
      return {
        members: ""
      }
    },
    created() {
      const year = new Date()
      const month = new Date()
      const day = new Date()

      BackendService.getMembers(year.getFullYear(), month.getMonth() + 1, day.getDate())
      .then((response) => {
        this.members = response.data
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
</script>

<style scoped>
  .small {
    max-width: 70vw !important;
    margin:  0 auto;
    padding: 0 0 20px 0;
  }

  tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }

 .btn-verloop {
   margin-top: 10px;
   background: #436d6a !important;
 }
</style>