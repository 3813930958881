import axios from "axios";
import router from "../router/index";

const apiUrl = process.env.VUE_APP_BACKEND_URL;
const apiClient = axios.create({
  baseURL: apiUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default {
  setToken() {
    const token = window.localStorage.getItem("token");
    if (token !== null) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  },

  loginRequest(user, password) {
    return apiClient.post("/oauth2/token/create", {
      username: user,
      password: password,
      grant_type: "password",
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
    });
  },
  
  login(user, password) {
    return this.loginRequest(user, password)
      .then(({ data }) => {
        window.localStorage.setItem("token", data.access_token);
        this.setToken();
      });
  },

  // ------- User profile ---------

  getUserProfile() {
    this.setToken();
    return apiClient.get("/api/v1/user/me")
      .catch(error => {
        if (error.response.status == 401) {
          router.push("/login");
        } else {
          throw error;
        }
      });
  },

  // --------- STATISTICS ----------

  getAssociations(year, month, day) {
    this.setToken(); 

    return apiClient.get("/api/v1/statistics/association", {
      params: {
        year, month, day
      }}
    )
  },
  getMembers(year, month, day) {
    this.setToken();

    return apiClient.get("/api/v1/statistics/member", { 
      params: {
        year, month, day
      }
    })
  },
  getShop(fromDate, toDate) {
    this.setToken();

    return apiClient.get("api/v1/statistics/shop", {
      params: {
        fromDate, toDate
      }
    })
  },
  getDisplayOrder(fromDate, toDate) {
    this.setToken();
    
    return apiClient.get("api/v1/statistics/display-order", {
      params: {
        fromDate, toDate
      }
    })
  },
  getTurnover(fromDate, toDate) {
    this.setToken();

    return apiClient.get("api/v1/statistics/turnover", {
      params: {
        fromDate, toDate
      }
    })
  },
  getGroup(year, month, day) {
    this.setToken();

    return apiClient.get("api/v1/statistics/group", {
      params: {
        year, month, day
      }
    })
  },
  getBrand(year, month, day) {
    this.setToken();

    return apiClient.get("api/v1/statistics/brand", {
      params: {
        year, month, day
      }
    })
  },

  // Briefverwerking 

  getMember(number) {
    this.setToken()

    return apiClient.post("api/v1/member/find", {
        membershipNumber: number
    })
  },
  
  confirmMember(email, number) {
    this.setToken() 

    return apiClient.post("api/v1/member/confirm", {
      email: email,
      membershipNumber: number
    })
  },

  uploadPhoto(number, email, file) {
    this.setToken() 

    const formData = new FormData();

    console.log(email)
    
    formData.append("membershipNumber", number);
    formData.append("email", email);
    formData.append("photo", file, file.name);

    return apiClient.post("api/v1/member/upload-photo", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    })
  },
  createSubscriptionAndInvoice(number, invoice) {
    this.setToken() 

    return apiClient.post("api/v1/subscription/create-subscription", {
      invoiceByPost: invoice,
      membershipNumber: number
    })
  }
};
