<template>
  <v-app id="nio-app">
    <LoadingScreen :isLoading="isLoading" />
    <main v-if="!isLoading">
      <side-nav></side-nav>
      <v-app-bar class="no-border-shadow" dark fixed app>
        <v-app-bar-nav-icon
          @click.stop="toggleDrawer"
          class="hidden-sm-and-down"
        ></v-app-bar-nav-icon>
        <v-toolbar-title
          ><img
            @click.stop="$router.push({ name: 'home' })"
            src="/img/logo_nfn.svg"
            class="logo-header-white"
            height="40"
          />
        </v-toolbar-title>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <v-layout>
            <router-view></router-view>
            <v-snackbar
              v-for="notification in notifications"
              :key="notification.id"
              :color="notification.type"
              top
              :timeout="2000"
              :value="true"
              multi-line
            >
              {{ notification.message }}
              <v-btn
                dark
                text
                @click="removeNotification(notification)"
                class="no-capitalized"
                >Sluiten</v-btn
              >
            </v-snackbar>
            <v-btn
              fixed
              fab
              bottom
              right
              color="dark"
              @click.stop="toggleDrawer"
              class="hidden-md-and-up no-border-shadow hamburger-menu"
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </v-layout>
        </v-container>
      </v-main>
      <v-footer dark app>
        <span class="white--text">&copy; {{ year }} - NFN</span>
      </v-footer>
    </main>
  </v-app>
</template>

<script>
import SideNav from "./components/SideNav";
import LoadingScreen from "./components/LoadingScreen";
import { mapState, mapActions } from "vuex";

export default {
  name: 'App',
  components: { SideNav, LoadingScreen },

  data: () => ({
    showNotification: true,
    isLoading: false,
    associations: []
  }),
  mounted() {
    if (this.$route.query.noreload === undefined) {
      console.log('loading dialog');
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 3000);
    }

  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    ...mapState("notification", ["notifications"])
  },
  methods: {
    ...mapActions("drawer", ["toggleDrawer"]),
    ...mapActions("notification", { removeNotification: "remove" })
  }
};
</script>

<style scoped>
.white--text {
  text-align: center;
}
</style>