import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Relaties from "@/views/Relaties.vue";
import Ledenopbouw from "@/views/Ledenopbouw.vue";
import Ledenverloop from "@/views/Ledenverloop.vue";
import Verenigingen from "@/views/Verenigingen.vue";
import Webshop from "@/views/Webshop.vue";
import Advertenties from "@/views/Advertenties.vue";
import Briefverwerking from "@/views/Briefverwerking.vue";
import Hoe from "@/views/Hoe.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "start",
    component: Login
  },
  {
    path: "/dashboard",
    name: "home",
    component: Home
  },
  {
    path: "/relaties",
    name: "relaties",
    component: Relaties,
    meta: { auth: true }
  },
  {
    path: "/relaties/ledenopbouw",
    name: "ledenopbouw",
    component: Ledenopbouw,
    meta: { auth: true }
  },
  {
    path: "/relaties/ledenverloop",
    name: "ledenverloop",
    component: Ledenverloop,
    meta: { auth: true }
  },
  {
    path: "/verenigingen",
    name: "verenigingen",
    component: Verenigingen,
    meta: { auth: true }
  },
  {
    path: "/webshop",
    name: "webshop",
    component: Webshop,
    meta: { auth: true }
  },
  {
    path: "/advertenties",
    name: "advertenties",
    component: Advertenties,
    meta: { auth: true }
  },
  {
    path: "/briefverwerking",
    name: "briefverwerking",
    component: Briefverwerking,
    meta: { auth: true }
  },
  {
    path: "/hoewerkthet",
    name: "hoewerkthet",
    component: Hoe
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/logout",
    name: "logout",
    component: Login
  },

  // otherwise redirect to error page
  { path: "*", redirect: "/" }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    const loggedIn = localStorage.getItem("user");
    if (!loggedIn) {
      next({ name: "login" });
      // return;
    }
  }
  next();
});

export default router