<template>
  <div>
    <div class="d-flex justify-space-between page-title">
      <div>
        <h1>Hoe werkt het?</h1>
        </div>
    </div>  
    <div>
      <strong>Inloggen</strong>
      <p>Om de app te kunnen gebruiken moet je inloggen. Hiervoor kan in het NFN beheer platform een gebruikersaccount voor gemaakt worden. Bij een gebruikersaccount moet je specifiek aangeven dat je gebruik mag maken van deze app.</p>

      <strong>Dashboard</strong>
      <p>Je start op een overal dashboard pagina waar je direct een overzicht krijgt over het aantal leden, de omzet van de shop en advertenties. Per onderdeel kun je doorklikken naar de details behorende bij dat onderdeel.</p>

      <strong>Menu</strong>
      <p>Je kunt ook via het menu naar de verschillende onderdelen navigeren. Als je een onderdeel aanklikt in het menu dan wordt die pagina gestart en bijbehorende gegevens worden opgehaald. Je kunt via het menu ook altijd weer naar de startpagina en vanuit daar weer doorgaan.</p>

      <strong>Datum selectie maken</strong>
      <p>Een aantal pagina's toont gegevens van de actuele maand. Hier kun je altijd de periode wijzigen via 'Kies een periode'. Door de begindatum en einddatum aan te klikken wordt een periode geselecteerd. Klik op OK en je krijgt de gegevens getoond met betrekking tot de geselecteerde periode.</p>

      <strong>Herladen</strong>
      <p>Wil je de app weer even op beginstand zetten, zoals je app start, klik dan op 'Herladen'. De app wordt dan opnieuw geladen.</p>
    </div>

    <v-btn outlined class="btn-terug" text min-width="100%" to="/dashboard">Terug</v-btn>
    
  </div>
</template>

<script>
  export default {
    components: {},
    data () {
      return {}
    },
  }
</script>

<style scoped>
</style>