export const namespaced = true;

export const state = {
  showDrawer: false
};

export const mutations = {
  TOGGLE_DRAWER(state) {
    state.showDrawer = !state.showDrawer;
  },
  SET_DRAWER(state, value) {
    state.showDrawer = value;
  }
};

export const actions = {
  toggleDrawer({ commit }) {
    commit("TOGGLE_DRAWER");
  },
  setDrawer({ commit }, show) {
    commit("SET_DRAWER", show);
  }
};
