<template>
  <div>
    <div class="d-flex justify-space-between page-title">
      <div>
        <h1>Advertenties</h1>
        <h5>{{ formattedDate() }}</h5>
      </div>
    </div> 

    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="dates"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          v-model="dates"
          readonly
          v-bind="attrs"
          v-on="on"
          class="choose-month-btn"
        >
          Kies een periode
        </v-btn>
      </template>
      <v-date-picker
        v-model="dates"
        range
        scrollable
        color="#74bcb7"
        min="2020-11"
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="modal = false"
        >
          Annuleren
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.dialog.save(dates) | fetchShop()"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>

    <div class="dashboard-card">
      <v-simple-table>
        <tbody>
          <tr>
            <td>Omzet</td>
            <td>€{{ Number(displayOrder.amount).toLocaleString() }}</td>
          </tr>
          <tr>
            <td>Aantal orders</td>
            <td>{{ displayOrder.new_orders }}</td>
          </tr>
          <tr>
            <td>Afgeronde orders</td>
            <td>€{{ Number(displayOrder.paid_items_amount).toLocaleString() }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    
    <div class="dashboard-card">
      <v-card-subtitle>Staafdiagram:</v-card-subtitle>
      <div class="small">
        <chart :chart-data="datacollection"></chart>
      </div>
    </div>

    <v-btn outlined class="btn-terug" text min-width="100%" to="/dashboard">Terug</v-btn>

  </div>
</template>

<script>
import BackendService from "../services/BackendService"
import Chart from '../components/Chart.js'

export default {
  components: {
    Chart
  },
  data () {
    return {
      datacollection: {},
      items: ['Oktober', 'November', 'December'],
      displayOrder: {},
      dates: [],
      modal: false
    }
  },
  created() {
    const lastYear = new Date().getFullYear() - 1
    const thisYear = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const day = new Date().getDate()
    const startDate = [lastYear, month, day].join('-')
    const today = [thisYear, month, day].join('-')

    BackendService.getDisplayOrder(startDate, today)
    .then((response) => {
      this.displayOrder = response.data
      
      this.datacollection = {
        labels: [this.dates],
        datasets: 
        [
          {
            label: 'Totaalbedrag',
            backgroundColor: '#ffd948',
            data: [this.displayOrder.amount]
          }
        ]
      }
    })
    .catch(err => {
      console.log(err)
    })
  },
  methods: {
    fetchShop() {
      BackendService.getDisplayOrder(this.dates[0], this.dates[1])
      .then((response) => {
        this.displayOrder = response.data

        this.datacollection = {
          labels: [this.dates],
          datasets: 
          [
            {
              label: 'Totaalbedrag',
              backgroundColor: '#ffd948',
              data: [this.displayOrder.amount]
            }
          ]
        }
      })
      .catch(err => {
        console.log(err)
      })      
    }
  }
}
</script>

<style scoped>

p.omzetTotaal {
  margin: 25px;
  font-size: 0.8em;
  text-align: center;
}

</style>