import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router/index'
import store from './store'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    formattedDate () {
      const date = new Date();

      return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'full' }).format(date);
    }
  },
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')