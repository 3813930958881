<template>
	<div :class="{ loader: true, fadeout: !isLoading }">
	</div>
</template>

<script>
export default {
	name: "LoadingScreen",
	props: ["isLoading"],
}
</script>

<style>
.loader {
	background: url("/img/splashscreen.png") #ffffff no-repeat center center fixed;
	background-color: #74BCB7;
	background-size: cover;
	bottom: 0;
	color: #000000;
	display: block;
	font-size: 32px;
	left: 0;
	overflow: hidden;
	padding-top: 10vh;
	position: fixed;
	right: 0;
	text-align: center;
	top: 0;
}

.splash-img-logo {
	width: 50%;
}

.fadeout {
	animation: fadeout 1ms forwards;
}

@keyframes fadeout {
	to {
		opacity: 0;
		visibility: hidden;
	}
}
</style>
